import React, { useContext, Fragment, useRef } from "react";
import { Link } from "gatsby";
import { FooterBottomData, NavLinksData } from "@/data";
import { MenuContext } from "@/context/menu-context";

const MobileMenu = (props) => {
    const { menuStatus, updateMenuStatus } = useContext(MenuContext);
    const menuEl = useRef(null);
    const handleMenuClick = e => {
        e.preventDefault();
        updateMenuStatus(!menuStatus);
    };
    return (
        <div className="mobile-menu animated fadeInLeft">
            <div className="mobile-menu__overlay" onClick={handleMenuClick}></div>
            <div className="mobile-menu__inner">
                <div className="mobile-menu__top">
                    <h2 className="mobile-menu__logo" style={{textTransform: "none"}}>
                        <a href="/">TOP</a>
                        <a href="/" style={{color: "#ba1d16"}}>aper</a>
                    </h2>
                    <a href="#" className="mobile-menu__close" onClick={handleMenuClick}>
                        <i className="fa fa-times"></i>
                    </a>
                </div>
                <nav className="mobile-menu__links" ref={menuEl}>
                    <ul>
                        {NavLinksData.map((links, index) => {
                            return (
                                <li
                                    key={index}
                                    className={`${undefined !== links.subItems ? "menu-item-has-children" : ""
                                        }`}
                                >
                                    <Link to={links.url}>{links.name}</Link>
                                    {undefined !== links.subItems ? (
                                        <Fragment>
                                            <button
                                                onClick={e => {
                                                    menuEl.current
                                                        .querySelectorAll(".sub-menu")
                                                        .forEach(item => {
                                                            item.classList.remove("show");
                                                        });

                                                    let clickedItem = e.currentTarget.parentNode;
                                                    clickedItem
                                                        .querySelector(".sub-menu")
                                                        .classList.toggle("show");
                                                }}
                                            >
                                                <i className="fa fa-angle-down"></i>
                                            </button>
                                            <ul className="sub-menu">


                                                {/* {links.subItems.map((subLinks, index) => (
                                                    <li key={index}>
                                                        <Link to={subLinks.url}>{subLinks.name}</Link>
                                                    </li>
                                                ))} */}

                                                {props.serving.edges.map((cat, index) => {
                                                    if (cat.node.children.edges && cat.node.children.edges.length > 0) {
                                                        return (
                                                            <li key={index}>
                                                                <Link to={`/${cat.node.slug}/`}>{cat.node.name}</Link>
                                                            </li>
                                                        )
                                                    }
                                                })}

                                            </ul>
                                        </Fragment>
                                    ) : null}
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                {/* <div className="mobile-menu__text">
          Printify is a premium Template for Digital Agencies, Start Ups, Small
          Business and a wide range of other agencies.
        </div> */}
                <div className="mobile-menu__socials pt-8">
                    {FooterBottomData.social.map(({ icon, url }, index) => {
                        return (
                            <a key={index} href={url} target="_blank" className={`${icon} hvr-pulse`}></a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
