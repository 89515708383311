import React, { Fragment, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link as ScrollLink } from "react-scroll";
import MobileMenu from "./mobile-menu";
import { MenuContext } from "@/context/menu-context";

import "@fontsource/pacifico/400.css";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/300-italic.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/400-italic.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/500-italic.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/600-italic.css";
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/700-italic.css";
import "@fontsource/rubik/800.css";
import "@fontsource/rubik/800-italic.css";
import "@fontsource/rubik/900.css";
import "@fontsource/rubik/900-italic.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/css/animate.min.css";
import "@/plugins/fontawesome-5/css/fontawesome-all.min.css";
import "@/plugins/icofont/css/icofont.css";
import "@/plugins/printify-icon/style.css";
import "@/css/hover.min.css";
import "@/css/style.css";
import "@/css/noPurge.css";
import "@/css/responsive.css";

const Layout = ({ PageTitle, serving, metaData, children }) => {
    const [scrollTop, setScrollTop] = useState(false);
    const { menuStatus } = useContext(MenuContext);
    const handleScrollTop = () => {
        if (window.scrollY > 70) {
            setScrollTop(true);
        } else if (window.scrollY < 70) {
            setScrollTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScrollTop);
        return () => {
            window.removeEventListener("scroll", handleScrollTop);
        };
    }, [scrollTop]);

    return (
        <Fragment>
            {/* <Helmet>
                <title>
                    {PageTitle} - Printify - Printing company GatsbyJS Template
                </title>
            </Helmet> */}


            {metaData && (
                <Helmet>
                    <html lang={"el"} />
                    <title>{metaData.title}</title>
                    <meta name="description" content={metaData.description} />
                    <link rel="canonical" href={metaData.canonical} />
                    <meta
                        name="viewport"
                        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                    />

                    {(metaData.noIndex && metaData.noIndex === "noindex") && (
                        <meta name="robots" content="noindex, nofollow" />
                    )}

                    <meta property="og:title" content={metaData.fbTitle} />
                    <meta property="og:description" content={metaData.fbDescription} />
                    <meta property="og:url" content={metaData.canonical} />
                    <meta property="og:type" content={metaData.fbType} />
                    <meta property="og:locale" content={metaData.locale} />
                    <meta property="og:image" content={metaData.image} />
                    <meta property="og:image:width" content="598" />
                    <meta property="og:image:height" content="399" />

                </Helmet>
            )}


            <div id="wrapper">{children}</div>

            {true === menuStatus ? <MobileMenu serving={serving} /> : null}
            {scrollTop === true ? (
                <ScrollLink
                    to="wrapper"
                    smooth={true}
                    duration={500}
                    id="backToTop"
                    className="scroll-to-top scroll-to-target"
                >
                    <i className="fa fa-angle-up"></i>
                </ScrollLink>
            ) : null}
        </Fragment>
    );
};

export default Layout;
